@import './vars';

@media screen and (max-width: 1024px) {
  .bree_description_content, .bree_copyright_box{
    justify-content: center;
  }
  .bree_story{
    width: 100%;
  }
  .bree_footer{
    padding: 10px;
  }
  .bree_letter{
    width: 100%;
  }
  .bree_footer-inner{
    flex-direction: column-reverse;
    row-gap: 30px;
  }
  .rbee_header_logo-gif{
    width: 60px;
  }
  .breeheader_logo-link{
    font-size: 23px;
  }
  #bree_search__form{
    padding: 5px;
  }
  .breeheader_logo_1{
    display: flex;
  }
  .breeheader_logo_2{
    display: none;
  }
  .rbee__blueSidebar ul{
    margin-left: 40px;
  }
  .bree_music__title{
    justify-content: flex-start;
    margin-left: 30px;
  }
  .breeheader_menu{
    align-items: flex-start;
  }
  .breeheader{
    padding: 10px 0;
  }
  .rbee__blueSite {
    padding: 15px 10px;
  }
  .rbee__blueWrapper {
    width: 100%;
  }
  .breeheader_logo{
    margin-bottom: 0;
  }
  .rbee__blueSidebar {
    box-sizing: border-box;
    display: none;
    flex-direction: column;
    width: 320px;
    padding: 5px;
    position: absolute;
    z-index: 2000;
    right: 0;
    top: 0;
    margin-top: 91px;
    border-radius: 0;
  }
  .breeheader_menu {
    grid-column-start: 1;
    grid-column-end: 3;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .breemobile__button {
    display: flex;
    justify-self: end;
  }
  .bree_mute-box__muteUnmute {
    display: none;
  }
  .breeheader_menu a {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .bree_mute-box {
    display: none;
  }
  //.bree_letter__link{
  //    background: var(--clr-3);
  //}

}

@media screen and (max-width: 992px) {
  .bree_melodious__list a {
    font-size: 24px;
  }
}

@media screen and (max-width: 920px) {
}

@media screen and (max-width: 768px) {
  body, html{
    background-image: none;
  }
  .ya-share2, .ya-share2 *{
    display: none;
  }
  .bree_letter__heading{
    margin-bottom: 10px;
  }
  .bree_footer-inner{
    row-gap: 10px;
  }
  .bree_letter__link{
    width: 25px;
    height: 25px;
    font-size: 14px;
    margin-bottom: 5px;
    margin-left: 3px;
    margin-right: 3px;
  }
  .breemobile__button{
    position: absolute;
    right: 15px;
    top: 25px;
  }
  .breeheader_inner{
    flex-direction: column;
    row-gap: 10px;
  }
  .bree_search{
    width: 100%;
  }
  .breeheader{
    margin-bottom: 20px;
  }
  .breemobile__button-line{
    height: 3px;
    margin-bottom: 3px;
    border-top: 3px;
  }
  .rbee_header_logo-png{
    width: 45px;
    height: 45px;
  }
  .rbee__bluePopular_title {
    font-size: 20px;
  }
  .bree_player-title_heading {
    margin-right: 0;
  }
  .bree_track__leftWrapper {
    justify-self: end;
  }
  .bree_track-central {
    margin-right: 0;
  }
  .bree_results-info__card {
    padding: 15px 10px;
  }
  .bree_melodious__list {
    flex-direction: row;
  }
  .bree_melodious__list a {
    margin-right: 5px;
    margin-left: 5px;
  }
  .bree_boxline__bottom {
    text-align: center;
  }
  .bree_mute-box {
    margin-right: 30px;
  }
  .bree_about {
    flex-direction: column;
  }
  .bree_logo-link {
    display: none;
  }
  .bree_wrapper {
    margin-left: 0;
  }

  .bree_mobile-nav__button {
    width: min-content;
    justify-self: end;
  }
  .bree_song-aplhabet {
    width: auto;
  }
  .bree_popular_title {
    font-size: 25px;
  }
  .bree_popular_logo {
    width: 40px;
    height: 40px;
  }
  .bree_copyright_box-mail {
    font-size: 24px;
  }
  .bree_sidebar {
    display: none;
  }
  .bree_wrapper {
    width: 100%;
  }
  .bree_melodious__list a::before {
    display: none;
  }
  .bree_footer_copy {
    width: 100%;
  }
  .bree_copyright_box-mail {
    font-size: 20px;
  }
  span.bree_shareblock h1 {
    font-size: 20px;
  }
  span.bree_shareblock a {
    font-size: 20px;
  }
  .breeheader_menu a {
    margin-left: 3px;
  }


}

@media screen and (max-width: 624px) {
  .bree_description_content{
    text-align: center;
  }
  .breeheader_menu a{
    width: 100%;
  }
  .breeheader_menu{
    margin-left: 0;
  }
  .bree_copyright_box-mail a {
    margin-left: 0;
  }
  .track {
    display: flex;
    //flex-direction: column;
  }
  .bree_copyright_box {
    margin-bottom: 10px;
  }
  .social-box {
    right: 30px;
  }

  .bree_about {
    margin-bottom: 190px;
  }
  .bree_track-central {
    margin-right: 0;
  }
  .bree_track-info {
    margin-bottom: 10px;
  }
  .bree_mute-box {
    width: auto;
    justify-self: center;
    grid-column-start: 2;
    margin-right: 0;
  }
  .bree_time {
    margin-right: 15px;
  }
  .bree_zero-time {
    font-size: 13px;
  }
  .bree_time_space {
    font-size: 13px;
  }
  .bree_timeBox {
    font-size: 13px;
  }
  .bree_copyright_box-mail {
    flex-direction: column;
  }
  span.bree_shareblock{
    left:15%;
  }
}

@media screen and (max-width: 670px) {
  span.bree_shareblock a {
    font-size: 15px;
  }
  .rbee__blueContainer{
    padding: 0 10px;
  }
  .rbee__card_download svg{
    width: 40px;
    height: 40px;
  }
  .rbee__card_like svg{
    width: 35px;
    height: 35px;
  }
  .dur{
    font-weight: 700;
    font-size: 14px;
  }
  .rbee__card_link::before, .rbee__card.plays a.rbee__card_link::before{
    width: 40px;
    height: 40px;
  }
  .rbee__card{
    padding: 10px 5px;
  }
  .bree_melodious__list a {
    justify-content: center;
  }
  //span.bree_shareblock{
  //  width: 96%;
  //  left: 0%;
  //}
}

@media screen and (max-width: 577px) {
  .rbee__bluePopular__box{
    justify-content: center;
    margin-bottom: 10px;
  }
  .rbee_header_logo-png {
    display: none;
  }
  .bree_popular_logo {
    display: none;
  }
  .bree_popular_title {
    text-align: center;
  }
  .bree_sotial-network-vk_btn {
    width: 100%;
    margin-bottom: 10px;
  }
  .bree_sotial-network-vk_link {
    width: 100%;
  }
  .bree_sotial-network-vk_input {
    flex-direction: column;
  }


  .bree_download_vk {
    width: 100%;
  }
  .bree_vk {
    width: 100%;
    min-width: auto;
  }
  .bree_social-box {
    padding-bottom: 0;
  }
  .bree_player-title_heading {
    text-align: center;
    margin-top: 20px;
    margin-bottom: -50px;
  }
  .bree_results-info__card_download {
    width: 40px;
    height: 40px;
  }
  .title {
    font-size: 12px;
  }
  .bree_input-content {
    font-size: 14px;
  }
  .rbee__card_time {
    font-size: 12px;
  }
  .rbee__card_download {
    right: 2px;
  }
  .rbee__card_download svg {
    width: 30px;
  }
  span.bree_shareblock{
    left:8%
  }

}


@media screen and (max-width: 510px) {
  .bree_result-of-search_link, .pages{
    font-size: 13px;
  }
  .bree_track-info {
    margin-right: 0;
  }
  .bree_player-title {
    margin-top: 0;
  }
  .bree_copyright_box-mail {
    font-size: 22px;
    margin-top: 10px;
  }
  .bree_player-title_heading {
    text-align: center;
    margin-top: 10px;
  }
  .progress-bar__container {
    flex-direction: column;
  }
  .rbee__blueSocial_vk {
    display: none;
  }
  .res-card_st-bf_results-info__card-subtext, .bree_subinfo__time {
    display: none;
  }
  .bree_player-title {
    text-align: center;
    margin-left: 0;
    justify-content: center;
    margin-bottom: 0;
  }
  .lbree_description {
    margin-bottom: 20px;
  }
  .track {
    display: flex;
    flex-direction: column;
  }
  .bree_mute-box {
    display: none;
  }
  .bree_track-info {
    flex-direction: column;
    align-items: center;
  }
  .bree_track-central {
    margin-bottom: 10px;
  }
  .bree_copyright_box-mail{
    font-size: 20px;
  }
  span.bree_shareblock{
    width: 96%;
    left: 0%;
  }
  .bree_track-info, .bree_track-central {
    height: 15px;
    margin-top: 30px;
    margin-bottom: 0px;
  }

}

@media screen and (max-width: 470px) {
  .bree_track-central_left-btn svg, .bree_track-central_right-btn svg{
    width: 27px;
    height: 27px;
  }
  .bree_description_content {
    display: none;
  }
  
  .bree_player-title_heading {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: -40px;
    margin-left: 0;
  }
  .bree_track-info, .bree_track-central {
    height: 15px;
    margin-top: 30px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 425px) {
  .breeheader_inner{
    align-items: flex-start;
  }
  .rbee__blueContainer{
    padding: 0 5px;
  }
  .rbee__card-subtext{
    display: none;
  }
  .bree_description_title {
    display: none;
  }
  .rbee_card_artist, .rbee_card_tracklink {
    max-height: 17px;
  }
  .breeheader_menu {
    flex-direction: column;
  }
  .breeheader_menu a {
    font-size: 24px;
  }
  .rbee_card_download svg {
    width: 40px;
    height: 25px;
  }
  .rbee_card_download {
    width: 40px;
    height: 20px;
  }

  .rbee_card {
    height: 70px;
    padding: 10px 5px 5px 5px;
  }
  .rbee_card_time {
    display: none;
  }
  .rbee_card .start {
    transform: scale(0.6);
  }
  .rbee_card_download {
    position: static;
  }
  .rbee_card-subtext {
    display: none;
  }
  .res-card_st-bf_results-info__card {
    padding: 15px 10px;
  }
  .bree_popular__box {
    justify-content: center;
  }
  .bree_melodious__heading {
    text-align: center;
  }
  .bree_description_title {
    text-align: center;
  }
  .bree_subinfo__time {
    display: none;
  }
  .bree_results-info__card_time {
    margin-right: 0;
  }
  .bree_results-info__card-subtext {
    margin-right: 10px;
    margin-left: 15px;
  }
  .bree_results-info__card_artist, .bree_results-info__card_space, .bree_results-info__card_tracklink {
    font-size: 14px;
  }
  .bree_results-info__card_like {
    display: none;
  }

  .bree_results-info__card_download svg {
    width: 30px;
    height: 30px;
  }
  .bree_results-info__card .start {
    width: 40px;
    height: 40px;
  }
  .bree_vk::before {
    min-width: calc(300px + 6px);
  }
  .bree_melodious__list {
    display: flex;
    flex-direction: column;
  }
  .bree_melodious__list a {
    font-size: 30px;
  }
  .breeheader_logo-link {
    font-size: 30px;
  }

  .bree_copyright_box-mail {
    font-size: 18px;
  }
  .bree_letter__link {
    font-size: 14px;
  }
  .bree_story_heading {
    font-size: 18px;
  }
  .bree_story_track-link {
    font-size: 14px;
    margin-right: 5px;
    padding: 3px;
  }
  .breeheader_logo-link {
    font-size: 22px;
  }
  .bree_genre-item_link {
    font-size: 12px;
  }
  .breeheader_menu a {
    font-size: 15px;
  }
  span.bree_shareblock h1 {
    font-size: 14px;
  }
  p {
    font-size: 14px !important;
  }

  .breeheader_menu a {
    margin-left: 0px;
  }

}

@media screen and (max-width: 375px) {
  span.bree_shareblock {
    width: 92%;
    top: 43%;
    left: 0%;
  }


} 